import { useDispatch } from "react-redux";
import {
  addToSingleCart,
  addtoCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

// import { useGTM } from "react-gtm-module";
import TagManager from "react-gtm-module";

const Card = ({ product }) => {
  // const { dataLayer } = useGTM();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cartQuantity] = useState(1);
  const [colorAtr] = useState(null);
  const [sizeAtr] = useState(null);

  const addToCartHandler = (product) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "add_to_cart", // Event name
        buttonName: "addtocart", // Additional data
        productId: `${product?.id}`, // Additional data
        productName: `${product?.name}`, // Additional data
        productPrice: `${product?.unit_price}`, // Additional data
      },
    });
    dispatch(addtoCart(product));
  };

  const handleBuyNow = () => {
    dispatch(removeAllFromCart());
    dispatch(
      addToSingleCart({
        ...product,
        sizeAtr,
        colorAtr,
        cartQuantity,
        variantPrice: product.discount ? product.discount : product.unit_price,
      })
    );
    navigate("/checkout");
  };

  return (
    <div className=" w-full  shadow-md rounded-md overflow-hidden">
      <Link
        to={`/productdetails/${product.id}`}
        className=" img aspect-square w-full h-[9.75rem] md:h-[14rem]  flex  justify-center items-center  overflow-hidden"
      >
        <img
          src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
          alt={`${product.name}`}
          className="w-full h-full md:object-cover object-cover  "
        />
      </Link>
      <div className="p-2 flex flex-col">
        <Link to={`/productdetails/${product?.id}`}>
          <div className="flex  items-center justify-center font-semibold">
            {+product.discount > 0 ? (
              <div className="flex justify-between items-center text-center gap-2">
                <span className="font-semibold line-through text-[#FA5303] decoration-200">
                  ৳{product?.unit_price}
                </span>
                <span className="">৳{+product?.after_discount}</span>
              </div>
            ) : (
              <div className="flex items-center justify-center">
                ৳{product?.unit_price}
              </div>
            )}
          </div>
          <h3
            className={`text-[1rem] ${
              product?.name.substring(0, 15) ? "" : "h-10"
            }   font-semibold text-[#212121] mb-2 `}
          >
            {product?.name.substring(0, 30)}
          </h3>
        </Link>

        <div className="flex flex-col text-center  gap-2 justify-center items-center text-gray-500">
          <button
            onClick={() => addToCartHandler(product)}
            className="border-[1px] border-[#042038] text-black  text-slate-50 hover:bg-[#042038] hover:text-white duration-300 w-full py-1 rounded-full"
          >
            Add to Cart
          </button>

          <button
            onClick={handleBuyNow}
            className="border text-center  bg-[#042038] hover:text-slate-50 ] text-white duration-300 w-full  rounded-full py-1 "
          >
            Order Now
          </button>

          {/* to={"/productdetails/"} */}
        </div>
      </div>
    </div>
  );
};

export default Card;
