import { Link } from "react-router-dom";
// import SectionTitle from "../components/SectionTitle";

import FlexCard from "./FlexCard";
const CategoryProductSection = ({ combinedData }) => {
  return (
    <>
      {combinedData?.map(
        (category) =>
          category?.products.length > 0 && (
            <section
              key={category?.id}
              className="product-section container mx-auto px-3 mb-5"
            >
              <div className="header-section  mt-10 mb-5  flex justify-end ">
                <Link
                  to={`/categorypage/${category?.id}`}
                  className="font-semibold  text-xl"
                >
                  View All
                </Link>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-12 gap-5">
                <div className="grid-span-full md:col-span-5 lg:col-span-4 relative overflow-hidden">
                  <img
                    src={`${process.env.REACT_APP_URL}/uploads/category/${category?.banner}`}
                    className="w-auto h-auto max-h-full max-w-full object-cover rounded-md"
                    alt={category?.name}
                  />
                  <h3 className="absolute bottom-3  text-2xl w-full text-center font-semibold text-white">
                    {category?.name}
                  </h3>
                </div>
                <div className="md:col-span-7 lg:col-span-8">
                  <div className="products-container gap-5  grid grid-cols-2 md:grid-cols-3 lg:grid-cols-8 ">
                    {category?.products?.slice(0, 8).map((product) => (
                      <div className=" col-span-1 md:col-span-1 lg:col-span-2">
                        <FlexCard key={product?.id} product={product} />
                      </div>
                    ))}
                    {category?.products?.length === 0 && (
                      <p className="text-center col-span-full">
                        No Products Found
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )
      )}
    </>
  );
};

export default CategoryProductSection;
